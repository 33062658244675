<template>
  <div class="col-12">
    <div class="card card-body">
      <div class="col-12 table-responsive">
        <h4 v-if="loading">جاري التحميل...</h4>
        <table class="table table-hover table-bordered">
          <thead>
            <th>التاريخ</th>
            <th>النوع</th>
            <th>عرض</th>
          </thead>
          <tbody>
            <tr v-for="a in arr" :key="a._id">
              <td>
                {{ a.date }}
              </td>
              <td>
                {{
                  a.type
                    .replace("1", "كل فصل في لجنة")
                    .replace("2", "توزيع تسلسلي (توزيع كل صف في عامود)")
                    .replace("3", "توزيع ديناميكي")
                }}
              </td>
              <td class="text-right">
                <button
                  class="btn btn-block btn-relief-success"
                  @click="$router.push('/create#' + a._id)"
                >
                  <i class="fa fa-eye"></i>
                  عرض
                </button>
                &nbsp;
                <button
                  class="btn btn-sm text-danger"
                  title="حذف العملية"
                  @click="deleteThis(a._id)"
                >
                  <i class="fa fa-trash"></i> حذف العملية
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      arr: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/committees/history", {
      jwt: user.jwt,
    })
      .then(function (a) {
        g.arr = a.response;
        g.loading = false;
      })
      .catch(function (a) {
        alert("حدث خطأ");
        g.loading = false;
      });
  },
  methods: {
    deleteThis(id) {
      if (confirm("متأكد من حذف هذه العملية نهائياً؟")) {
        var g = this;
        g.loading = true;
        $.post(api + "/committees/delete", {
          jwt: user.jwt,
          id: id,
        })
          .then(function (a) {
            location.reload();
          })
          .catch(function (a) {
            alert("حدث خطأ");
            g.loading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>